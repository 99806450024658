
import DialogTemplate from '@/views/DialogTemplate.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheConfirmDialog',
  components: { Pic, DialogTemplate },
  props: {
    hasRenew: Boolean,
  },
})
