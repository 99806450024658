/**
 * @author ayou[beica1@outook.com]
 * @date 2022/1/13 17:42
 * @description
 *   prime.api.ts of WeTrade
 */
import requestResult from '@/common/request/request'
import withAccountUpdate from '@/decorators/withAccountUpdate'

export const subscribe = withAccountUpdate(requestResult('/api/user/prime/subscribe'))

export const unsubscribe = requestResult('/api/user/prime/unSubscribe')
